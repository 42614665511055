import React from 'react';
//import css
import './App.css';

const HomePage = () => {
  return (
    <div className="homepage">
      <img src="/ai.jpg" alt="AI Image" className='ai-image'/>
      <h1>欢迎来到我们的AI世界</h1>
      <p>我们致力于研究和开发最先进的人工智能技术，以实现更智能化的生活和工作方式。</p>
      <p>我们的团队由一群来自世界各地的科学家和工程师组成，他们共同致力于开发出最优秀的AI算法和应用程序。</p>
      <p>在我们的网站上，您可以了解有关我们的工作和成就的更多信息，并浏览我们最新的AI产品和服务。</p>
    </div>
  );
};

export default HomePage;
